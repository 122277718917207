import React, { useContext, useEffect, useState, FC } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form, Tabs, Tab, FormLabel } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PersonModel } from "../../models/PersonModel";
import { CoupleFile, CoupleType, PersonFile, PersonType } from "../../types/PersonType";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import PersonsSelect from "../../components/PersonsSelect";
import * as yup from "yup";
import { useFormik } from "formik";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import TempLayout from "../../temp/04_receptoras_isis-4.png";
import StateList from "../../components/form/StateList";
import clsx from "clsx";
import CityList from "../../components/form/CityList";
import TranslateTerms from "../../components/TranslateTerms";
import Error from "../../components/messages/Error";
import { FileUploadProvider, FileUploadProviderContext } from "../../providers/FileUploadProvider";
import FileUploader from "../../components/FileUploader";
import { FormattedMessage, useIntl } from "react-intl";
import { OptionsContext } from "../../providers/OptionsContextProvider";
import { LANG_COMMON_BACK, LANG_COMMON_CHOOSE, LANG_COMMON_DATA, LANG_COMMON_FILES, LANG_COMMON_NO, LANG_COMMON_OPTIONS, LANG_COMMON_OTHER_DATA, LANG_COMMON_PATIENT, LANG_COMMON_PATIENTS, LANG_COMMON_SAVE, LANG_COMMON_YES, LANG_PERSON_HAS_CHILDREN, LANG_PERSON_HOBBIES, LANG_PERSON_OCCUPATION, LANG_PERSON_SCHOOLING } from "../../lang";
import AddressForm from "../../components/form/AddressForm";
import PersonEditModal from "./components/modal/PersonEditModal";
import { CoupleModel } from "../../models/CoupleModel";
import PersonBasicForm from "./components/form/PersonBasicForm";
import PersonAddModal from "./components/modal/PersonAddModal";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../providers/ImageUploadProvider";
import ImageUploader from "../../components/ImageUploader";
import PersonCard from "./components/PersonCard";
import { MedicalRecordType } from "../../types/MedicalRecordType";
import { CoupleMedicalRecordContext } from "../../providers/CoupleMedicalRecordContextProvider";
import MedicalRecordEvolutionAddModal from "./components/modal/MedicalRecordEvolutionAddModal";
import MedicalRecordEvolutionEntry from "./components/MedicalRecordEvolutionEntry";
import "./medical-record-container.css"
import MedicalRecordTreatmentTab from "./components/MedicalRecordTreatmentTab";
import MedicalRecordExamTab from "./components/MedicalRecordExamTab";
import MedicalRecordDocumentTab from "./components/MedicalRecordDocumentTab";

interface Props {
    onSave?: () => void,
    onCancel?: () => void,
    id?: number | string
}

const CoupleMedicalRecordContainer: FC<Props> = ({ onSave, onCancel, id }) => {
    const intl = useIntl();
    const options = useContext(OptionsContext);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [couple, setCouple] = useState<CoupleType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [shouldSubmitAddress, setShouldSubmitAddress] = useState(false);
    const [showErrorValidation, setShowErrorValidation] = useState(false);
    const [files, setFiles] = useState<CoupleFile[] | undefined>([]);
    const [showPartnerModal, setShowPartnerModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditPrimaryModal, setShowEditPrimaryModal] = useState(false);
    const [showEditSecondaryModal, setShowEditSecondaryModal] = useState(false);

    const [showAddMedicalRecordNursingEvolution, setShowAddMedicalRecordNursingEvolution] = useState(false);
    const [showAddMedicalRecordMedicalEvolution, setShowAddMedicalRecordMedicalEvolution] = useState(false);
    const [key, setKey] = useState<null | string>('nursing');

    const loadCouple = () => {
        setLoading(true);
        if (id !== undefined) {
            CoupleModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setCouple(resp);
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    const loadFiles = () => {
        setLoading(true);
        if (id !== undefined) {
            CoupleModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    useEffect(() => {
        loadCouple();
    }, []);

    const showEvolutionModal = () => {
        if (key === 'nursing') {
            setShowAddMedicalRecordNursingEvolution(true);
        } else {
            setShowAddMedicalRecordMedicalEvolution(true)
        }
    }

    const submit = (couple: CoupleType, shouldSend: boolean = false) => {
        if (shouldSend) {
            setShowErrorValidation(false);
            setSaving(true);
            CoupleModel().update(couple.id, { ...couple }).then((resp) => {
                if (typeof resp !== "boolean") {
                    toast.success("Registro atualizado com sucesso!");
                    //onSave();
                } else {
                    toast.error("Houve um erro, tente novamente!");
                    setHasSubmitted(false);
                    setShouldSubmitAddress(false);
                    setSaving(false);
                }
            });
        } else {
            setShouldSubmitAddress(true);
            setTimeout(() => setShouldSubmitAddress(false), 100);
        }
    }

    return (
        <CoupleMedicalRecordContext.Consumer>
            {({ loading, medical_record, reload }) => (
                <>
                    {loading && (<Loader fullscreen={true} />)}
                    {medical_record && (
                        <>
                            {showAddMedicalRecordNursingEvolution && (
                                <MedicalRecordEvolutionAddModal
                                    medical_record={medical_record}
                                    type="N"
                                    show={true}
                                    onSave={(c) => {
                                        reload();
                                        setShowAddMedicalRecordNursingEvolution(false);
                                    }}
                                    onHide={() => {
                                        setShowAddMedicalRecordNursingEvolution(false);
                                    }}
                                />
                            )}
                            {showAddMedicalRecordMedicalEvolution && (
                                <MedicalRecordEvolutionAddModal
                                    medical_record={medical_record}
                                    type="M"
                                    show={true}
                                    onSave={(c) => {
                                        reload();
                                        setShowAddMedicalRecordMedicalEvolution(false);
                                    }}
                                    onHide={() => {
                                        setShowAddMedicalRecordMedicalEvolution(false);
                                    }}
                                />
                            )}
                            <PersonEditModal
                                show={showEditPrimaryModal}
                                person={medical_record.couple?.primary}
                                onHide={() => setShowEditPrimaryModal(false)}
                                onSave={(person) => {
                                    reload();
                                    setShowEditPrimaryModal(false);
                                }}
                            />
                            {medical_record.couple?.secondary && (
                                <PersonEditModal
                                    show={showEditSecondaryModal}
                                    person={medical_record.couple?.secondary}
                                    onHide={() => setShowEditSecondaryModal(false)}
                                    onSave={(person) => {
                                        reload();
                                        setShowEditSecondaryModal(false);
                                    }}
                                />
                            )}
                            <Row>
                                <Breadcrumb>
                                    <Breadcrumb.Item><Link to={'/admin/couples'}><FormattedMessage {...LANG_COMMON_PATIENTS} /></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>{CoupleModel().formatCoupleName(medical_record.couple)}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                            <Row>
                                <Col lg={{ offset: 0 }}>
                                    <Card>
                                        <CardHeader className={'d-flex'}>
                                            <Card.Title>{intl.formatMessage(LANG_COMMON_PATIENT)}</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <Form>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5 className="person-name">{CoupleModel().formatCoupleName(medical_record.couple)}</h5>
                                                    </div>
                                                    <Col lg={3}>
                                                        <PersonCard
                                                            person={medical_record.couple.primary}
                                                            onSelect={(person) => {
                                                                setShowEditPrimaryModal(true);
                                                            }}
                                                        />
                                                    </Col>
                                                    {medical_record.couple.secondary && (
                                                        <Col lg={3}>
                                                            <PersonCard
                                                                person={medical_record.couple.secondary}
                                                                onSelect={(person) => {
                                                                    setShowEditSecondaryModal(true);
                                                                }}
                                                            />
                                                        </Col>
                                                    )}
                                                </div>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                    <Card className="my-4">
                                        <CardHeader className={'d-flex'}>
                                            <div className="card-title h5">
                                                <FormattedMessage {...LANG_COMMON_DATA} /> {" "}
                                            </div>
                                        </CardHeader>
                                        <Card.Body>
                                            <Tabs
                                                onSelect={(key) => setKey(key)}
                                            >
                                                <Tab eventKey="all-evolution" title="Todos os registros">                                                    
                                                    {medical_record.all_evolution.map((item) => (
                                                        <MedicalRecordEvolutionEntry
                                                            medical_record_evolution={item}
                                                            onSelect={() => { }}
                                                        />
                                                    ))}
                                                </Tab>
                                                <Tab eventKey="nursing" title="Enfermagem">
                                                    <Row>
                                                        <Col lg={{ span: 4 }}>
                                                            <FormLabel>Profissional</FormLabel>
                                                            <CustomSelect
                                                                disabled={true}
                                                                placeholder="Selecione"
                                                                name="treatment"
                                                                onChange={() => { }}
                                                            />
                                                        </Col>
                                                        <Col lg={{ span: 3, offset: 5 }} className="d-flex" style={{ alignItems: 'center' }}>
                                                            <Button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    showEvolutionModal();
                                                                }}
                                                                className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100 btn btn-primary">NOVO REGISTRO</Button>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                    {medical_record.nursing_evolution.map((item) => (
                                                        <MedicalRecordEvolutionEntry
                                                            medical_record_evolution={item}
                                                            onSelect={() => { }}
                                                        />
                                                    ))}
                                                </Tab>
                                                <Tab eventKey="medical" title="Evolução Médica">
                                                    <Row>
                                                        <Col lg={{ span: 4 }}>
                                                            <FormLabel>Profissional</FormLabel>
                                                            <CustomSelect
                                                                disabled={true}
                                                                placeholder="Selecione"
                                                                name="treatment"
                                                                onChange={() => { }}
                                                            />
                                                        </Col>
                                                        <Col lg={{ span: 3, offset: 5 }} className="d-flex" style={{ alignItems: 'center' }}>
                                                            <Button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    showEvolutionModal();
                                                                }}
                                                                className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100 btn btn-primary">NOVO REGISTRO</Button>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                    {medical_record.medical_evolution.map((item) => (
                                                        <MedicalRecordEvolutionEntry
                                                            medical_record_evolution={item}
                                                            onSelect={() => { }}
                                                        />
                                                    ))}
                                                </Tab>
                                                <Tab eventKey="treatments" title="Tratamentos">
                                                    <MedicalRecordTreatmentTab
                                                        active_treatment={medical_record.active_treatment}
                                                        medical_record={medical_record}
                                                    />
                                                </Tab>
                                                <Tab eventKey="exams" title="Exames">
                                                    <MedicalRecordExamTab
                                                        medical_record={medical_record}
                                                    />
                                                </Tab>
                                                <Tab eventKey="documents" title="Documentos">
                                                    <MedicalRecordDocumentTab
                                                        medical_record={medical_record}
                                                    />
                                                </Tab>
                                            </Tabs>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={{ span: 3 }}>
                                    <Card>
                                        <CardHeader>
                                            <Card.Title>{intl.formatMessage(LANG_COMMON_FILES)}</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {files?.map((personFile) => (
                                                        <>
                                                            <FileUploadProvider url={`/api/couple/${id}/file/${personFile.file_id}`} onChange={loadFiles} key={personFile.file_id}>
                                                                <FileUploadProviderContext.Consumer>
                                                                    {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                                        <FileUploader
                                                                            hasUploadError={hasUploadError}
                                                                            hasDownloadError={false}
                                                                            uploadErrorMessage={uploadErrorMessage}
                                                                            download={download}
                                                                            loading={loading}
                                                                            url={url}
                                                                            edit={false}
                                                                            onChange={onChange}
                                                                            onRemove={onRemove}
                                                                            uploadUrl={``}
                                                                            removeUrl={`/api/couple/${id}/file/${personFile.file_id}`}
                                                                            remove={true}
                                                                            name={personFile.file.name}
                                                                        />
                                                                    )}
                                                                </FileUploadProviderContext.Consumer>
                                                            </FileUploadProvider>
                                                            <hr />
                                                        </>
                                                    ))}
                                                </div>
                                                <div className="col-sm-12">
                                                    <FileUploadProvider url={undefined} onChange={() => { }} onDone={loadFiles}>
                                                        <FileUploadProviderContext.Consumer>
                                                            {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                                <FileUploader
                                                                    hasUploadError={hasUploadError}
                                                                    hasDownloadError={false}
                                                                    uploadErrorMessage={uploadErrorMessage}
                                                                    download={download}
                                                                    loading={loading}
                                                                    url={url}
                                                                    onChange={onChange}
                                                                    onRemove={onRemove}
                                                                    uploadUrl={`/api/couple/${medical_record.couple.id}/file`}
                                                                    removeUrl={""} />
                                                            )}
                                                        </FileUploadProviderContext.Consumer>
                                                    </FileUploadProvider>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card className="my-4">
                                        <CardHeader>
                                            <Card.Title>{intl.formatMessage(LANG_COMMON_OPTIONS)}</Card.Title>
                                        </CardHeader>
                                        <Card.Body>
                                            <div className="row">
                                                <Col lg={{ span: 8, offset: 2 }}>
                                                    <Button
                                                        onClick={onCancel}
                                                        variant="outline-light" size={'lg'} style={{ "width": "100%" }} className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100">{intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                                </Col>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
        </CoupleMedicalRecordContext.Consumer>
    );
}

export default CoupleMedicalRecordContainer;