import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import DashboardPage from "../modules/dashboard/DashboardPage";
import UserPage from "../modules/user/UserPage";
import BusinessPage from "../modules/business/BusinessPage";
import BusinessEditPage from "../modules/business/BusinessEditPage";
import BusinessAddPage from "../modules/business/BusinessAddPage";
import { useIdleTimer } from 'react-idle-timer';
import Confirm from "../components/messages/Confirm";
import {AuthContext} from "../providers/AuthContextProvider";
import {useAuth} from "../hooks/useAuth";
import {useAPI} from "../hooks/useAPI";
import SubscriptionPage from "../modules/subscription/SubscriptionPage";
import DashboardSubscriptionPage from "../modules/dashboard/DashboardSubscriptionPage";
import ErrorBoundary from "../components/ErrorBoundary";
import CouplePage from "../modules/couple/CouplePage";
import CoupleEditPage from "../modules/couple/CoupleEditPage";
import CoupleMedicalRecordPage from "../modules/couple/CoupleMedicalRecordPage";

const PrivateRoutes = () => {
    const {setIsAuth} = useContext(AuthContext);
    const {deleteToken} = useAuth();
    const {doLogout} = useAPI();
    const navigate = useNavigate();
    const [showIdleModal, setShowIdleModal] = useState(false);

    const onPrompt = () => {
        setShowIdleModal(true);
    }

    const onIdle = () => {
        setShowIdleModal(false);
        doLogout();
        deleteToken();
        setIsAuth(false);
        navigate("/auth/login");
    }

    const {
        start,
        reset
    } = useIdleTimer({
        onPrompt,
        onIdle,
        timeout: 1000 * 60 * 10,
        promptTimeout: 1000 * 60,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ],
        element: document,
        startOnMount: true,
        crossTab: true,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    });

    useEffect(start, []);

    return (
        <>
            <Confirm
                message={"Você ainda está aí? Você será desconectado em 60 segundos caso não confirme."}
                show={showIdleModal}
                onConfirm={() => {
                    setShowIdleModal(false);
                    reset();
                }}
                onHide={() => {
                    setShowIdleModal(false);
                    reset();
                }} />
            <ErrorBoundary>
                <Routes>
                    <Route path={'/admin/dashboard'} element={<DashboardPage />} />
                    <Route path={'/admin/subscriptions'} element={<DashboardSubscriptionPage />} />
                    <Route path={'/'} element={<CouplePage />} />
                    <Route path={'/admin/users'} element={<UserPage />} />
                    <Route path={'/admin/businesses'} element={<BusinessPage />} />
                    <Route path={'/admin/businesses/:id/edit'} element={<BusinessEditPage />} />
                    <Route path={'/admin/businesses/add'} element={<BusinessAddPage />} />                    
                    <Route path={'/admin/couples'} element={<CouplePage />} />
                    <Route path={'/admin/couple/:id/edit'} element={<CoupleEditPage />} />                    
                    <Route path={'/admin/couple/:id/medical-record'} element={<CoupleMedicalRecordPage />} />
                    <Route path={'/admin/subscription'} element={<SubscriptionPage />} />
                </Routes>
            </ErrorBoundary>
        </>
    );
}

export default PrivateRoutes;