import React, { useContext, useEffect, useState, FC } from "react";
import { Button, Card, Col, Container, Row, Table, Breadcrumb, Form } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PersonModel } from "../../models/PersonModel";
import { CoupleFile, CoupleType, PersonFile, PersonType } from "../../types/PersonType";
import Loader from "../../components/messages/Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import PersonsSelect from "../../components/PersonsSelect";
import * as yup from "yup";
import { useFormik } from "formik";
import { OptionType } from "../../types/CommonType";
import CustomSelect from "../../components/form/CustomSelect";
import TempLayout from "../../temp/04_receptoras_isis-4.png";
import StateList from "../../components/form/StateList";
import clsx from "clsx";
import CityList from "../../components/form/CityList";
import TranslateTerms from "../../components/TranslateTerms";
import Error from "../../components/messages/Error";
import { FileUploadProvider, FileUploadProviderContext } from "../../providers/FileUploadProvider";
import FileUploader from "../../components/FileUploader";
import { FormattedMessage, useIntl } from "react-intl";
import { OptionsContext } from "../../providers/OptionsContextProvider";
import { LANG_COMMON_BACK, LANG_COMMON_CHOOSE, LANG_COMMON_DATA, LANG_COMMON_FILES, LANG_COMMON_NO, LANG_COMMON_OPTIONS, LANG_COMMON_OTHER_DATA, LANG_COMMON_PATIENT, LANG_COMMON_PATIENTS, LANG_COMMON_SAVE, LANG_COMMON_YES, LANG_PERSON_HAS_CHILDREN, LANG_PERSON_HOBBIES, LANG_PERSON_OCCUPATION, LANG_PERSON_SCHOOLING } from "../../lang";
import AddressForm from "../../components/form/AddressForm";
import PersonEditModal from "./components/modal/PersonEditModal";
import { CoupleModel } from "../../models/CoupleModel";
import PersonBasicForm from "./components/form/PersonBasicForm";
import PersonAddModal from "./components/modal/PersonAddModal";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../providers/ImageUploadProvider";
import ImageUploader from "../../components/ImageUploader";
import PersonCard from "./components/PersonCard";

interface Props {
    onSave: () => void,
    onCancel: () => void,
    onMedicalRecord: () => void,
    id: number | string
}

const CoupleEditContainer: FC<Props> = ({ onSave, onCancel, id, onMedicalRecord }) => {
    const intl = useIntl();
    const options = useContext(OptionsContext);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [couple, setCouple] = useState<CoupleType>();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [shouldSubmitAddress, setShouldSubmitAddress] = useState(false);
    const [showErrorValidation, setShowErrorValidation] = useState(false);
    const [files, setFiles] = useState<CoupleFile[] | undefined>([]);
    const [showPartnerModal, setShowPartnerModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const loadCouple = () => {
        setLoading(true);
        if (id !== undefined) {
            CoupleModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setCouple(resp);
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    const loadFiles = () => {
        setLoading(true);
        if (id !== undefined) {
            CoupleModel().get(id).then((resp) => {
                if (typeof resp !== "boolean") {
                    setFiles(resp.files);
                }
                setLoading(false);
            });
        }

    }

    useEffect(() => {
        loadCouple();
    }, []);

    const submit = (couple: CoupleType, shouldSend: boolean = false) => {
        if (shouldSend) {
            setShowErrorValidation(false);
            setSaving(true);
            CoupleModel().update(couple.id, { ...couple }).then((resp) => {
                if (typeof resp !== "boolean") {
                    toast.success("Registro atualizado com sucesso!");
                    onSave();
                } else {
                    toast.error("Houve um erro, tente novamente!");
                    setHasSubmitted(false);
                    setShouldSubmitAddress(false);
                    setSaving(false);
                }
            });
        } else {
            setShouldSubmitAddress(true);
            setTimeout(() => setShouldSubmitAddress(false), 100);
        }
    }

    return (
        <>
            {couple !== undefined && (
                <PersonAddModal
                    couple_id={couple?.id}
                    show={showAddModal}
                    onHide={() => setShowAddModal(false)}
                    onSave={(person) => {
                        setCouple({ ...couple, secondary: person });
                        setShowAddModal(false);
                        setShowEditModal(true);
                    }}
                    type={"S"} />
            )}
            {couple !== undefined && couple?.secondary && (
                <PersonEditModal
                    show={showEditModal}
                    person={couple?.secondary}
                    onHide={() => setShowEditModal(false)}
                    onSave={(person) => {
                        setCouple({ ...couple, secondary: person });
                        setShowEditModal(false);
                    }}
                />
            )}
            {loading && (<Loader fullscreen={true} />)}
            <Error
                show={showErrorValidation}
                onHide={() => {
                    setShowErrorValidation(false);
                }}
                message={`Preencha corretamente os campos marcados em vermelho e tente novamente.`} />
            {couple && (
                <>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to={'/admin/couples'}><FormattedMessage {...LANG_COMMON_PATIENTS} /></Link></Breadcrumb.Item>
                            <Breadcrumb.Item active>{CoupleModel().formatCoupleName(couple)}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 0 }}>
                            <Card>
                                <CardHeader className={'d-flex'}>
                                    <Card.Title>{intl.formatMessage(LANG_COMMON_DATA)}</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5 className="person-name">{CoupleModel().formatCoupleName(couple)}</h5>
                                            </div>
                                        </div>
                                        {couple && (
                                            <PersonBasicForm
                                                person={couple.primary}
                                                hasSubmitted={hasSubmitted}
                                                onValidationError={() => {
                                                    setShowErrorValidation(true);
                                                }}
                                                onSubmit={(person) => {
                                                    setCouple({ ...couple, primary: person });
                                                    setTimeout(() => {
                                                        submit({ ...couple, primary: person });
                                                    }, 100);
                                                }}
                                            />
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                            <Row className={"my-4"}>
                                <Col lg={12}>
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title h4">Endereço</div>
                                        </div>
                                        <div className="card-body">
                                            <AddressForm
                                                address={couple.address}
                                                hasSubmitted={shouldSubmitAddress}
                                                onSubmit={(data) => {
                                                    submit({ ...couple, address: data }, true);
                                                }}
                                                onValidationError={() => {
                                                    submit({ ...couple, address: undefined }, true);
                                                    //setShowErrorValidation(true);
                                                }}
                                                onValidate={(data) => { }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={{ span: 3 }}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Parceiro</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {!couple.secondary && (
                                        <div>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                setShowAddModal(true)
                                            }}>
                                                <span>Adicionar parceiro</span>
                                                <i className="bi bi-plus"></i>
                                            </a>
                                        </div>
                                    )}
                                    {couple.secondary && (
                                        <PersonCard
                                            person={couple.secondary}
                                            onSelect={(person) => {
                                                setShowEditModal(true);
                                            }}
                                        />
                                    )}
                                </Card.Body>
                            </Card>
                            <Card className="my-4">
                                <CardHeader>
                                    <Card.Title>{intl.formatMessage(LANG_COMMON_FILES)}</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {files?.map((personFile) => (
                                                <>
                                                    <FileUploadProvider url={`/api/couple/${id}/file/${personFile.file_id}`} onChange={loadFiles} key={personFile.file_id}>
                                                        <FileUploadProviderContext.Consumer>
                                                            {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                                <FileUploader
                                                                    hasUploadError={hasUploadError}
                                                                    hasDownloadError={false}
                                                                    uploadErrorMessage={uploadErrorMessage}
                                                                    download={download}
                                                                    loading={loading}
                                                                    url={url}
                                                                    edit={false}
                                                                    onChange={onChange}
                                                                    onRemove={onRemove}
                                                                    uploadUrl={``}
                                                                    removeUrl={`/api/couple/${id}/file/${personFile.file_id}`}
                                                                    remove={true}
                                                                    name={personFile.file.name}
                                                                />
                                                            )}
                                                        </FileUploadProviderContext.Consumer>
                                                    </FileUploadProvider>
                                                    <hr />
                                                </>
                                            ))}
                                        </div>
                                        <div className="col-sm-12">
                                            <FileUploadProvider url={undefined} onChange={() => { }} onDone={loadFiles}>
                                                <FileUploadProviderContext.Consumer>
                                                    {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                                        <FileUploader
                                                            hasUploadError={hasUploadError}
                                                            hasDownloadError={false}
                                                            uploadErrorMessage={uploadErrorMessage}
                                                            download={download}
                                                            loading={loading}
                                                            url={url}
                                                            onChange={onChange}
                                                            onRemove={onRemove}
                                                            uploadUrl={`/api/couple/${couple.id}/file`}
                                                            removeUrl={""} />
                                                    )}
                                                </FileUploadProviderContext.Consumer>
                                            </FileUploadProvider>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card className="my-4">
                                <CardHeader>
                                    <Card.Title>{intl.formatMessage(LANG_COMMON_OPTIONS)}</Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <Button
                                                onClick={onCancel}
                                                variant="outline-primary" size={'lg'} style={{ "width": "100%" }} 
                                                className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100"><i className="bi bi-arrow-left"/> {intl.formatMessage(LANG_COMMON_BACK)}</Button>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <Button
                                                onClick={onMedicalRecord}
                                                variant="outline-primary" size={'lg'} style={{ "width": "100%" }} 
                                                className="btn-custom btn-custom-light btn-custom-small text-uppercase w-100"><i className="bi bi-card-list"/> Ver Prontuário</Button>
                                        </div>
                                        <div className="col-md-12">
                                            <Button className="btn-custom bg-custom-gradient btn-custom-small text-uppercase w-100" variant="primary" size={'lg'} onClick={() => {
                                                setHasSubmitted(true);
                                                setTimeout(() => {
                                                    setHasSubmitted(false);
                                                }, 500);
                                            }} disabled={saving}>{saving ? <ClipLoader color={'#ffffff'} loading={saving} size={'12px'} /> : <><i className="bi bi-save"/> {intl.formatMessage(LANG_COMMON_SAVE)}</>}</Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}

export default CoupleEditContainer;