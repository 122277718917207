import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PersonType } from "../../../../types/PersonType";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomSelect from "../../../../components/form/CustomSelect";
import { OptionType } from "../../../../types/CommonType";
import ImageUploader from "../../../../components/ImageUploader";
import { ImageUploadProvider, ImageUploadProviderContext } from "../../../../providers/ImageUploadProvider";
import InputMask from 'react-input-mask';
import clsx from "clsx";
import moment from "moment";
import { OptionsContext } from "../../../../providers/OptionsContextProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { LANG_COMMON_CHOOSE, LANG_COMMON_LEGAL_ID, LANG_COMMON_MEDICAL_RECORD_NUMBER, LANG_COMMON_NAME, LANG_COMMON_NO, LANG_COMMON_REFFERAL, LANG_COMMON_REGISTRY_ID, LANG_COMMON_YES, LANG_PERSON_AGE, LANG_PERSON_BIRTH_DATE, LANG_PERSON_HAS_CHILDREN, LANG_PERSON_HOBBIES, LANG_PERSON_OCCUPATION, LANG_PERSON_OTHER_INFO, LANG_PERSON_OTHER_INFO_DETAILS, LANG_PERSON_PICTURE, LANG_PERSON_SCHOOLING } from "../../../../lang";
import { useUtil } from "../../../../hooks/useUtil";

interface Props {
    person: PersonType
    onSubmit: (values: PersonType) => void
    onUpdate?: (person: PersonType) => void
    onValidationError?: () => void
    hasSubmitted: boolean
}

const PersonBasicForm: FC<Props> = ({ person, onSubmit, onUpdate = undefined, hasSubmitted, onValidationError = () => { } }) => {
    const intl = useIntl();
    const options = useContext(OptionsContext);
    const util = useUtil();

    // Initialize and format birth date
    person = { ...person, birth_date: (person.birth_date !== undefined) ? moment(person.birth_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined }
    const [age, setAge] = useState((person.birth_date !== undefined) ? util.calculateAge(person.birth_date, 'DD/MM/YYYY') : 0);

    // Formik validation schema
    const schema = yup.object().shape({
        name: yup.string().required(),
        birth_date: yup.string().required(),
        notes: yup.string().nullable(),
        cpf: yup.string().required(),
        rg: yup.string().nullable(),
        refferal: yup.string().nullable(),
        schooling: yup.string().nullable(),
        has_children: yup.boolean().nullable(),
        hobbies: yup.string().nullable(),
        occupation: yup.string().nullable(),
    });


    // Formik initialization
    const formik: any = useFormik({
        initialValues: person,
        validationSchema: schema,
        onSubmit: () => { },
        validateOnMount: false
    });

    // Update person data on form update
    useEffect(() => {
        if (onUpdate !== undefined)
            onUpdate(formik.values);
    }, [formik.values]);

    // Watch for external submit event
    useEffect(() => {
        if (hasSubmitted) {
            for (let field in formik.values) {
                formik.setFieldTouched(field, true);
            }

            formik.validateForm(formik.values).then((errors: {}) => {
                if (Object.keys(errors).length === 0) {
                    onSubmit({...formik.values, birth_date: moment(formik.values.birth_date, 'DD/MM/YYYY').format('YYYY-MM-DD')})
                } else {
                    onValidationError();
                }
            });
        }
    }, [hasSubmitted]);

    return (
        <>
            <Row>
                <Col lg={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <ImageUploadProvider url={`/api/person/${person.id}/photo`} onChange={() => { }}>
                            <ImageUploadProviderContext.Consumer>
                                {({ url, onChange, onRemove, loading, hasUploadError, hasDownloadError, download, uploadErrorMessage }) => (
                                    <ImageUploader
                                        photoLabel={`${intl.formatMessage(LANG_PERSON_PICTURE)}`}
                                        hasUploadError={hasUploadError}
                                        hasDownloadError={hasDownloadError}
                                        uploadErrorMessage={uploadErrorMessage}
                                        download={download}
                                        loading={loading}
                                        height={175}
                                        width={145}
                                        url={url}
                                        onChange={onChange}
                                        onRemove={onRemove}
                                        uploadUrl={`/api/person/${person.id}/photo`}
                                        removeUrl={""} />
                                )}
                            </ImageUploadProviderContext.Consumer>
                        </ImageUploadProvider>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_COMMON_NAME)}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder={`${intl.formatMessage(LANG_COMMON_NAME)}`}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            isValid={formik.touched.name && !formik.errors.name}
                            isInvalid={!!formik.errors.name && formik.touched.name}
                        />
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label><FormattedMessage {...LANG_COMMON_LEGAL_ID} /></Form.Label>
                        <InputMask
                            disabled={true}
                            placeholder={intl.formatMessage(LANG_COMMON_LEGAL_ID)}
                            value={formik.values.cpf}
                            onChange={formik.handleChange}
                            name={"cpf"}
                            type="text"
                            mask="999.999.999-99"
                            className={clsx("form-control", {
                                "is-invalid": formik.touched.cpf && formik.errors.cpf,
                                "is-valid": !formik.errors.cpf && formik.touched.cpf
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_COMMON_REGISTRY_ID)}</Form.Label>
                        <Form.Control
                            type="text"
                            name="rg"
                            placeholder={`${intl.formatMessage(LANG_COMMON_REGISTRY_ID)}`}
                            value={formik.values.rg}
                            onChange={formik.handleChange}
                            isValid={formik.touched.rg && !formik.errors.rg}
                            isInvalid={!!formik.errors.rg && formik.touched.rg}
                        />
                    </Form.Group>
                </Col>
                <Col lg={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{intl.formatMessage(LANG_COMMON_REFFERAL)}</Form.Label>
                        <CustomSelect
                            name="refferal"
                            options={options.personOptions?.getRefferal()}
                            onChange={(option: OptionType) => formik.setFieldValue("refferal", option.value)}
                            value={formik.values.refferal}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.refferal}
                            isValid={formik.touched.refferal && !formik.errors.refferal}
                        />
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_BIRTH_DATE)}</Form.Label>
                        <InputMask
                            name={"birth_date"}
                            type="text"
                            mask="99/99/9999"
                            value={formik.values.birth_date}
                            onChange={(e) => {
                                if (e.target.value !== null) {
                                    formik.setFieldValue("birth_date", e.target.value);
                                    setAge(util.calculateAge(e.target.value, "DD/MM/YYYY"));
                                }
                            }}
                            className={clsx("form-control", {
                                "is-invalid": formik.touched.birth_date && formik.errors.birth_date,
                                "is-valid": !formik.errors.birth_date && formik.touched.birth_date
                            })}
                        />
                    </Form.Group>
                </Col>
                <Col lg={2}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_AGE)}</Form.Label>
                        <Form.Control type="text" placeholder="" disabled={true} value={age} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="my-3">
                    <hr />
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_SCHOOLING)}</Form.Label>
                        <CustomSelect
                            name="schooling"
                            options={options.personOptions?.getSchooling()}
                            onChange={(option: OptionType) => formik.setFieldValue("schooling", option.value)}
                            value={formik.values.schooling}
                            placeholder={`${intl.formatMessage(LANG_COMMON_CHOOSE)}`}
                            isSearchable={false}
                            isTouched={formik.touched.schooling}
                            isValid={formik.touched.schooling && !formik.errors.schooling}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_HAS_CHILDREN)}</Form.Label>
                        <Row>
                            <Col lg={3}>
                                <Form.Check
                                    type="radio"
                                    label={`${intl.formatMessage(LANG_COMMON_YES)}`}
                                    onClick={(e) => {
                                        formik.setFieldValue("has_children", true)
                                    }}
                                    checked={formik.values.has_children}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    label={`${intl.formatMessage(LANG_COMMON_NO)}`}
                                    onClick={(e) => {
                                        formik.setFieldValue("has_children", false)
                                    }}
                                    checked={!formik.values.has_children}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{intl.formatMessage(LANG_PERSON_OCCUPATION)}</Form.Label>
                        <Form.Control
                            type="text"
                            name="occupation"
                            placeholder={`${intl.formatMessage(LANG_PERSON_OCCUPATION)}`}
                            value={formik.values.occupation}
                            onChange={formik.handleChange}
                            isValid={formik.touched.occupation && !formik.errors.occupation}
                            isInvalid={!!formik.errors.occupation && hasSubmitted}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}

export default PersonBasicForm;