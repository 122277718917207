import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { useAPI } from "../../hooks/useAPI";

interface Props {
    options?: Array<any>
    onChange: any
    name: string
    value?: any
    placeholder?: string
    isSearchable?: boolean
    isValid?: boolean
    isTouched?: boolean
    disabled?: boolean
    isMulti?: boolean
    isClearable?: boolean
}
const CidSelect: FC<Props> = (
    {
        options = [],
        onChange,
        value = undefined,
        placeholder = `Selecionar...`,
        isSearchable = false,
        isValid = false,
        isTouched = false,
        disabled = false,
        isMulti = true,
        isClearable = true
    }) => {
    const { api } = useAPI();


    const promiseOptions = (inputValue: string) => {
        return new Promise<Array<any>>((resolve) => {
            api().get(`/api/cid?search=${inputValue}`).then((resp) => {
                let options: Array<any> = [];
                resp.data.map((value: any) => {
                    options.push({ "label": `${value.id} - ${value.description}`, "value": value.id })
                });
                resolve(options);
            })
        });
    }

    const getSelectedOption = (options: Array<any>, value: any) => {
        if (value === undefined || value === null) return undefined;
        for (let i in options) {
            if (options[i].value.toString() === value.toString()) {
                return options[i];
            }
        }

        return undefined;
    }

    const getSelectedOptions = (options: Array<any>, value: any) => {
        const selected = [];
        if (typeof value === 'string') {
            const values = value.split(";");
            for (let i in values) {
                selected.push(getSelectedOption(options, values[i]));
            }
        }

        return selected;
    }

    const [defaultValue, setDefaultValue] = useState(
        (!isMulti) ? getSelectedOption(options, value) : getSelectedOptions(options, value)
    );

    return (
        <AsyncSelect
            placeholder="Pesquisar pela descrição ou código"
            onChange={onChange}
            loadOptions={promiseOptions}
            loadingMessage={(inputValue) => "Carregando..."}
            noOptionsMessage={(inputValue) => "Sem resultados"}
            isDisabled={disabled}
            options={options}
            defaultValue={defaultValue}
            className={clsx("react-select-container", {
                "is-invalid": isTouched && !isValid,
                "is-valid": isTouched && isValid
            })}
            classNamePrefix={'react-select'}
            isMulti={isMulti}
            isClearable={isClearable}
            isOptionDisabled={(option) => option.isOptionDisabled === true}
        />
    )
}

export default CidSelect;